<script setup lang="ts">
import { CmsElementProductListing } from '@shopware-pwa/composables-next';
import { computed, ref } from 'vue';
import SwProductCard from '../SwProductCard.vue';
import { ShopwareSearchParams } from '@shopware-pwa/types';
import deepMerge from '../../../helpers/deepMerge';
import getTranslations from '../../../helpers/getTranslations';
import { Modal } from '@upa/design-system';
import { Pagination } from '@upa/design-system';

const props = defineProps<{
  content: CmsElementProductListing;
}>();

type Translations = {
  listing: {
    noProducts: string;
  };
};
let translations: Translations = {
  listing: { noProducts: 'No products found 😔' },
};
const globalTranslations = getTranslations();
translations = deepMerge(translations, globalTranslations) as Translations;
const route = useRoute();

let {
  search,
  getElements,
  setInitialListing,
  getCurrentPage,
  changeCurrentPage,
  getTotalPagesCount,
  getTotal,
  getCurrentListing
} = useListing({ listingType: 'categoryListing' });
if (route.query.query) {
  ({
    search,
    getElements, 
    setInitialListing,
    getCurrentPage,
    changeCurrentPage,
    getTotalPagesCount,
    getTotal,
    getCurrentListing
  } = useListing({ listingType: 'productSearchListing' }));

  await search({
    limit: 20,
    p: route.query.p || 1,
    query: route.query.query,
    properties: route.query.properties
  } as Partial<ShopwareSearchParams>), { preventRouteChange: true };
  
  if (getCurrentListing.value){
    setInitialListing(getCurrentListing.value);
  }
} else {
  setInitialListing(props?.content?.data?.listing);
}

const stItem = (route.query?.stItem || route.query?.stitem) as string;
if(stItem?.length) {
  const items = stItem.split(',');
  search({
    filter: [
      {
        type: 'equalsAny',
        field: 'productNumber',
        value: items
      }
    ]
  });
}

const router = useRouter();

const changePage = async (page: number) => {
  await router.push({
    query: {
      ...route.query,
      p: page,
    },
  });
  // changeCurrentPage(page, <Partial<ShopwareSearchParams>>route.query);
  changeCurrentPage(page, route.query as Partial<ShopwareSearchParams>);
};

const isProductListing = computed(
  () => props.content?.type === 'product-listing',
);



let issuuLink = ref('');
let visible = ref(false);

let closeIframe = () => {
  visible.value = false;
};

function openIssueViewer(product: any) {
  issuuLink.value = product.issuuLink;
  visible.value = true;
}
</script>

<template>
  <div class="bg-white cms-listing__container">
    <div class="listing">
      <div v-if="getElements.length" class="listing-content">
        <!-- // TODO: snippets, style, check if after filter change -->
        <div class="listing-total-amount">
          <span class="listing-total-amount__number">{{ getTotal }}</span>&nbsp;<span>{{ $t('listing.totalItems') }}</span>
        </div>
        <div class="flex flex-wrap">
          <SwProductCard
            v-for="product in getElements"
            :key="product.id"
            :product="product"
            :is-product-listing="isProductListing"
            class="listing-product-card"
            @open-issue-viewer="openIssueViewer(product)"
            @opens-modal-with-issuu-link="openIssueViewer(product)"
          />
          <Modal
            v-if="visible"
            :top-centered="true"
            class="cms-el-product-listing-issuu-modal"
            @layer-click="closeIframe"
            @escape-press="closeIframe"
            @close="closeIframe"
          >
            <template #content>
              <iframe class="onlineBrowse__iframe" :src="issuuLink" />
            </template>
          </Modal>
        </div>
        <div class="pagination__wrapper">
          <Pagination
            :total="getTotalPagesCount"
            :current="Number(getCurrentPage)"
            @change-page="changePage"
          />
        </div>
      </div>
      <div v-else>
        <h2 class="mx-auto text-center">
          {{ translations.listing.noProducts }}
        </h2>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.cms-listing__container {
  margin: -20px;
}

.listing {
  padding: 20px 0;
}

.listing-product-card {
  @media (min-width: 1498px) {
    // flex-grow: inherit;
    flex: 0 1 33%;
  }
}
</style>
